<template>
  <modal class="data-view-filters" :class="variant" ref="filters" hide-header hide-footer size="lg" @shown="filtersShown"
    @hide="$emit('update:open', false)" @hidden="cancelChanges">
    <page-header :id="`${id}-filters`" header-class="data-view-filters-container" :title="`Filter ${title}`" :icon="icon"
      :variant="variant" local hide-total>
      <!-- reset -->
      <btn :id="`${id}-filters-reset`" class="reset" variant="outline" :disabled="!filtersActive"
        @click="() => resetFilters(false)">
        <icon icon="undo"></icon>Reset Filters
      </btn>

      <!-- apply -->
      <btn :id="`${id}-filters-submit`" class="submit mr-2" :variant="variant" @click="submit">
        <icon class="mr-2" icon="check"></icon>Apply Filters
      </btn>

      <!-- bookmark -->
      <div v-tooltip.hover="{ customClass: 'bookmark' }" :title="bookmarkTooltipText" v-if="!filtersActive">
        <btn :variant="variant" :disabled="true" class="filter-icon">
          <icon icon="bookmark"></icon>
        </btn>
      </div>
      <btn :id="`${id}-filters-bookmark`" :variant="variant" class="filter-icon" v-show="filtersActive">
        <icon icon="bookmark"></icon>
      </btn>
      <popover :target="`${id}-filters-bookmark`" triggers="click blur" placement="bottom"
        @hidden="filterLabelError = false">
        <form @submit.prevent="filterSaveSubmit" class="bookmark-popover popover-pad"
          :class="{ loading: filterSaveState.loading }">
          <label for="save-filter-input">Add to My Filters As</label>
          <text-input required id="save-filter-input" v-model="filterSaveLabel" class="input" type="text"
            autocomplete="off" autocorrect="off" spellcheck="false" :placeholder="`Filter name...`"
            @input="filterLabelError = false" :class="{ invalid: filterLabelError }"></text-input>
          <span class="error-message" v-if="filterLabelError">
            This name has already been used, please choose another
          </span>
          <btn type="submit" :variant="variant" block>
            <span v-if="filterSaveState.loading">
              <spinner class="mr-3" :radius="30" width="8" /> Adding...
            </span>
            <span v-else>
              <icon class="mr-2" icon="bookmark"></icon>Add
            </span>
          </btn>
        </form>
      </popover>

      <!-- filter popover -->
      <btn :variant="variant" class="filter-icon" :id="`${id}-filters-settings`" v-tooltip.hover="'Manage my Filters'">
        <icon icon="cogs"></icon>
      </btn>
      <popover :target="`${id}-filters-settings`" triggers="click" placement="bottom" @shown="filterPopoverOpen = true"
        @hidden="filterPopoverOpen = false">
        <filter-popover :filters="filterState.response" :error="filterState.error" :id="id" :variant="variant"
          :schema="schema" :resource="resource" :headers="headers" :activeFields="activeFields" :query="query"
          :open="filterPopoverOpen" :loading="filterState.loading || filterSaveState.loading" @apply="applyFilter"
          @edit="editFilter" @cancel="cancelFilter" @delete="deleteFilter" @close="closeFilterPopup"
          @reload="getFilters" />
      </popover>

      <!-- close -->
      <btn :variant="variant" class="filter-icon" @click="cancelChanges">
        <icon size="lg" icon="times"></icon>
      </btn>
    </page-header>

    <div class="filter-banner" v-if="banner.show">
      <icon icon="check" class="mr-3"></icon>
      <strong class="mr-1" v-text="banner.filterName"></strong>
      <span>has been added to My Filters</span>
      <btn size="sm" :variant="variant" class="filter-icon close" @click="banner.show = false">
        <icon size="lg" icon="times"></icon>
      </btn>
    </div>

    <div class="filter-banner editing" v-if="activeFilterSelected">
      <div class="label">Editing Filter: {{ activeFilter.label }}</div>
      <div>
        <btn size="sm" :variant="variant" @click="clearActiveFilter">
          <icon icon="times"></icon> Cancel
        </btn>
        <btn size="sm" :variant="variant" @click="filterUpdate" :disabled="filterSaveState.loading"
          v-if="activeFilter.id !== 'default'">
          <icon icon="bookmark"></icon> Save
        </btn>
        <btn size="sm" :variant="variant" :id="`${id}-filters-bookmark-save-as`" :disabled="filterSaveState.loading">
          <icon icon="bookmark"></icon> Save As
        </btn>
        <popover :target="`${id}-filters-bookmark-save-as`" triggers="click blur" placement="bottom"
          @hidden="filterLabelError = false">
          <form @submit.prevent="filterSaveSubmit" class="bookmark-popover popover-pad"
            :class="{ loading: filterSaveState.loading }">
            <label for>Add to My Filters As</label>
            <text-input required v-model="filterSaveLabel" class="input" type="text" autocomplete="off" autocorrect="off"
              spellcheck="false" :placeholder="`Filter name...`" @input="filterLabelError = false"
              :class="{ invalid: filterLabelError }"></text-input>
            <span class="error-message" v-if="filterLabelError">
              This name has already been used, please choose another
            </span>
            <btn type="submit" :variant="variant" block>
              <span v-if="filterSaveState.loading">
                <spinner class="mr-3" :radius="30" width="8" /> Adding...
              </span>
              <span v-else>
                <icon class="mr-2" icon="bookmark"></icon>Add
              </span>
            </btn>
          </form>
        </popover>
      </div>
    </div>

    <div class="data-view-filters-body">
      <!-- select side bar -->
      <div class="aside" v-if="useMultForm">
        <div class="data-view-filters-filter-container">
          <div class="data-view-filters-tab" v-if="tab === ''">
            <h4>Filter Categories</h4>
            <button @click="studyTab">
              Study ({{ filterCounts.study }})
            </button>
            <button @click="(tab = 'participant') && (activeCTFinderType = 'filter-az')">
              Participant ({{ filterCounts.participant }})
            </button>
          </div>
            <ul class="data-view-filters-filter-list" :class="`show-${tab}`" ref="scroll" v-show="tab !== ''">
            <li class="data-view-filters-list-back" role="button" @click="tab = ''">
              <icon class="icon" icon="arrow-left"></icon>All Filters
            </li>
            <li class="data-view-filters-list-header" :class="tab">
              {{ tab | titleCase }} Filters
            </li>
            
            <!-- Participant tab - Groups/A-Z Tab (Lefthand side)-->
            <div v-if="tab === 'study'" class="filter-toggle-btn"> 
              <div class="container-button-group container-control btn-group">
                <btn @click="(activeCTFinderType = 'filter-groups') && (selectedTab = 'filter-groups')" 
                  :class="{'active-b': ((selectedTab=='null' || selectedTab=='undefined' || selectedTab=='') ? 'true' : selectedTab == 'filter-groups')}" 
                  class="studies" variant="studies" active>
                  Groups
                </btn>
                <btn @click="(activeCTFinderType = 'filter-az') && (selectedTab = 'filter-az') &&(advancedSearch = '')" 
                  :class="{ 'active-b': selectedTab === 'filter-az' }"
                  class="studies" variant="studies">A-Z</btn>
              </div>
            </div>

            <!-- Participant tab - Study Groups Filter -->
            <grouped-study-filters v-if="((selectedTab=='null' || selectedTab=='undefined' || selectedTab=='') ? 'true' : selectedTab == 'filter-groups') &&
              this.$route.name != 'studies' &&
              (this.$route.name != 'organizations' || 'investigators')
              " :searchedFields="searchedFields" :toggleField="toggleField" :advancedSearch="advancedSearch" :tab="tab"
            :toggleFieldVisible="toggleFieldVisible" :resetFilters="resetFilters" :filtersActive="filtersActive" ref="grouped-study-filters"></grouped-study-filters>

            <!-- Studies & Participant tab - A-Z filters -->
            <az-study-filters v-if="selectedTab == 'filter-az' || tab != 'study'" :searchedFields="searchedFields" :toggleField="toggleField"
            :toggleFieldVisible="toggleFieldVisible" @clear-search="clearSearch" :resetFilters="resetFilters" :filtersActive="filtersActive" :advancedSearch="advancedSearch" ref="az-study-filters"></az-study-filters>
            <li class="advanced-filter-list-item empty" v-if="!searchedFields.length">
              No filters found
            </li>
            <li class="advanced-filter-list-item clear" v-if="advancedSearch.length" @click.prevent="advancedSearch = ''">
              <a href="#">Clear Search</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Studies tab - Groups/A-Z Tab  -->
      <div class="aside" v-else>
        <div class="data-view-filters-filter-container">
          
          <!-- The tab on the left Groups/A-Z Tab for Studies Only -->
          <div v-if="this.$route.name === 'studies'" class="filter-toggle-btn">
            <div class="container-button-group container-control btn-group">
              <btn @click="clearGroupedFilters"
                :class="{ 'active-b': ((selectedTab=='null' || selectedTab=='undefined' || selectedTab=='') ? 'true' : selectedTab == 'filter-groups') }" class="studies" variant="studies" active>
                Groups</btn>
              <btn @click="(activeCTFinderType = 'filter-az') && (selectedTab = 'filter-az') &&(advancedSearch = '')" 
                :class="{ 'active-b': (selectedTab == 'filter-az') }"
                class="studies" variant="studies">A-Z</btn>
            </div>
          </div>
          <div class="data-view-filters-search">
            <icon class="icon" icon="search"></icon>
            <input ref="searchInput" placeholder="Search for a filter..." class="form-control" v-model="advancedSearch" />
          </div>

          <grouped-study-filters v-if="((selectedTab=='null' || selectedTab=='undefined' || selectedTab=='') ? 'true' : selectedTab == 'filter-groups') &&
            this.$route.name === 'studies'
            " :searchedFields="searchedFields" :toggleField="toggleField" :advancedSearch="advancedSearch" 
            :toggleFieldVisible="toggleFieldVisible" :resetFilters="resetFilters" :filtersActive="filtersActive" 
            @clear-search="clearSearch" ref="grouped-study-filters">
          </grouped-study-filters>
          <az-study-filters v-if="selectedTab == 'filter-az' || this.$route.name != 'studies'" 
            :searchedFields="searchedFields" :toggleField="toggleField"
            :toggleFieldVisible="toggleFieldVisible" @clear-search="clearSearch" 
            :resetFilters="resetFilters" :filtersActive="filtersActive" 
            :advancedSearch="advancedSearch" :originalSFields= "originalBindFields" ref="az-study-filters">
          </az-study-filters>
        </div>
      </div>

      <div class="body" ref="body" :class="{ useMultForm: 'multi-form', editing: activeFilterSelected }">
        <!-- Disease Maintype Indicator -->
        <disease-maintype-indicator :id="`filter-modal-disease-maintype-indicator`" v-if="showDataSettings()"></disease-maintype-indicator>
        
        <!-- form -->
        <!-- This is the right hand side of the Filters Window, where we can make Filter Selection provide/select values -->
        <!-- Form builder for investigators and organizations page since they do not have a separate Groups/A-Z Tab, they 
             only have one tab for selecting filters -->
        <form-builder v-if="this.$route.name != 'studies' && this.$route.name != 'participants'"
         class="data-view-filters-form" ref="filters-form" :render-fields="open" :context="context" :open="open"
          :id="`${id}-filters`" :class="`${id}-filters`" :schema="sortedSchema" :searchedFields="searchedFields" :values="values"
          :bind-values.sync="bindValues" :bind-fields.sync="bindFields" :persist="!noPersist"
          :onlyShowActive="onlyActiveFilters" :field-search="''" :last-field-index="lastFieldIndex" no-actions
          always-call-mounted show-active-on-mount @submit="filtersSubmit" @active="setCounts"></form-builder>
        
        <!-- Form builder for Studies and Participants since they have separate Groups/A-Z tab options-->
        <!-- Form buildre for A-Z tab-->
        <form-builder v-else-if="activeCTFinderType == 'filter-az'"
         class="data-view-filters-form" ref="filters-form" :render-fields="open" :context="context" :open="open"
          :id="`${id}-filters`" :class="`${id}-filters`" :schema="sortedSchema" :searchedFields="searchedFields" :values="values"
          :bind-values.sync="bindValues" :bind-fields.sync="originalFilters" :persist="!noPersist"
          :onlyShowActive="onlyActiveFilters" :field-search="''" :last-field-index="lastFieldIndex" no-actions
          always-call-mounted show-active-on-mount @submit="filtersSubmit" @active="setCounts"></form-builder>
        <!-- Form buildre for Groups tab-->
        <form-builder-groups-tab v-else-if="activeCTFinderType == 'filter-groups'"
         class="data-view-filters-form" ref="filters-form" :schema="sortedSchema" :searchedFields="searchedFields" 
           :render-fields="open" :context="context" :open="open" :activeCTFinder="'filter-groups'" 
           :routeName="this.$route.name"
          :id="`${id}-filters`" :class="`${id}-filters`" :values="values" :validateRegistrationDateValueIsNotNull="validateRegistrationDateValueIsNotNull"
          :bind-values.sync="bindValues" :bind-fields.sync="bindFields" :persist="!noPersist"
          :onlyShowActive="onlyActiveFilters" :field-search="''" :last-field-index="lastFieldIndex" no-actions
          always-call-mounted show-active-on-mount @submit="filtersSubmit" @active="setCounts"></form-builder-groups-tab>
        <!-- form empty -->
        <div class="data-view-fields-empty" v-if="!filtersVisible">
          <div>
            <icon icon="ban"></icon>No filters selected
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "bootstrap-vue/es/components/modal/modal";
import FormBuilder from "@/components/form/FormBuilder";
import FormBuilderGroupsTab from "@/components/form/FormBuilderGroupsTab";
import Defaults from "@/components/form/defaults";
import Popover from "bootstrap-vue/es/components/popover/popover";
import PageHeader from "@/views/page/PageHeader";
import Spinner from "@/components/utils/Spinner";
import FilterPopover from "./utils/FilterPopover";
import { handleIntroConfigs, startIntro } from "@/services/IntroService";
import {
  formatParams,
  formatStudiesParams,
  updateLDParamsForFilters,
} from "@/services/ParticpantsQueryService";
import { apiGet } from "@/services/DataService";
import { truthy } from "@/services/CommonsService";
import { setResourceFilter } from "@/services/UserFilterService";
import StudyKeys from "@/constants/StudyKeys.js";
import GroupedStudyFilters from "@/components/presentation/data-view/GroupedStudyFilters";
import AzStudyFilters from "@/components/presentation/data-view/AZStudyFilters";
import DiseaseMaintypeIndicator from "@/components/form/DiseaseMaintypeIndicator.vue";
import { showAge } from '../../form/schemas/AgeSchema';
import { hasSECFilterValues } from '../../form/utils/EligibilityCriteria';

export default {
  name: "data-view-filters",
  inheritAttrs: false,
  components: {
    PageHeader,
    Modal,
    FormBuilder,
    FormBuilderGroupsTab,
    Popover,
    FilterPopover,
    Spinner,
    GroupedStudyFilters,
    AzStudyFilters,
    DiseaseMaintypeIndicator,
  },
  props: [
    "open",
    "id",
    "variant",
    "title",
    "icon",
    "schema",
    "noPersist",
    "initialValues",
    "defaultValues",
    "activeFields",
    "bindParams",
    "showActionBtns",
    "introConfigs",
    "bindActiveFilter",
    "headers",
    "resource",
    "query",
    "context",
    "validateRegistrationDateValueIsNotNull",
    "originalBindFields",
  ],
  data() {
    return {
      activeCTFinderType: "filter-groups",
      bindValues: {},
      bindFields: [],
      values: {},
      fields: [],
      advancedSearch: "",
      onlyActiveFilters: false,
      openFiltersOnSubmit: false,
      filterPopoverOpen: false,
      observed: [],
      lastFieldIndex: -1,
      tab: "",
      bookmarkTooltipText:
        '"Add to My Filters" will be available once the filter selections below have been defined.',
      filterCounts: {
        participant: 0,
        study: 0,
        originalFilters: [],
      },
      filterQuery: {
        baseURL: "users",
        endpoint: "/user/filters",
      },
      banner: {
        show: false,
        filterName: "",
      },
      activeFilter: undefined,
      selectedTab: "",
      filterSaveLabel: "",
      userFilters: [],
      filterSaveState: {
        loading: false,
        error: undefined,
      },
      filterState: {
        response: [],
        loading: false,
        error: undefined,
      },
      filterLabelError: false,
    };
  },
  computed: {
    filterSaveQuery() {
      return {
        baseURL: "users",
        endpoint: "/user/filters",
        method: "POST",
        data: {
          // filter_type: '',
          filter_name: this.filterSaveLabel,
          filter_object: setResourceFilter(
            filterParams(this.$h.cloneDeep(this.bindValues)),
            this.resource
          ),
        },
      };
    },
    activeFilterSelected() {
      return this.$h.truthy(this.activeFilter);
    },
    hasDeveloper() {
      return this.$store.getters["auth/roles"].includes("DEVELOPER");
    },
    sortedSchema() {
      if (this.$route.name === "organizations") {
        return this.$h.cloneDeep(this.schema);
      } else {
        return this.$h.cloneDeep(this.schema).sort((a, b) => {
          let aLabel = this.$h.get(a, "group.label", "");
          let bLabel = this.$h.get(b, "group.label", "");
          return aLabel.localeCompare(bLabel);
        });
      }
    },
    searchedFields() {
      // Is it multiple lead disease?
      const isMLDorNoneLeadDisease =
        this.values["lead-disease"] &&
        Array.isArray(this.values["lead-disease"]) &&
        this.values["lead-disease"].length > 0 &&
        (this.values["lead-disease"][0].maintype === "Multiple Lead Diseases" ||
          this.values["lead-disease"][0].maintype === "None");

      // Set the type to not active for lead disease filter for 'Multiple Lead Diseases' or 'None'
      this.bindFields.forEach((filter, index, thisBindFields) => {
        if (filter.id === "lead-disease" && isMLDorNoneLeadDisease) {
          filter.state.active = false;
        }
        // Set the keyword filter state to not active if keyword is empty and only Target Keyword option is slected
        if (filter.id === "_keyword_form" && filter.value) {
          if ((!filter.value._keyword || filter.value?._keyword == '') && filter.value?.keyword_search_type?.length > 0) {
            filter.state.active = false
            // If a filter is not selected (not active), do not show it on the page.
            filter.applyFilter = false;
            filter.state.applyFilter = false;
          }
        }

        thisBindFields[index] = filter;
      });

      if(this.activeCTFinderType === "filter-groups"){
        // When user searches for a filter in Groups tab, check groupingFilterDisplayLabel first if the field has groupingFilterDisplayLabel
        // if not return the group label in the field.
        return this.bindFields.filter(
          (field) => {
            if(truthy(this.$h.get(field, "groupingFilterDisplayLabel.label", ""))){
              return this.$h
                .get(field, "groupingFilterDisplayLabel.label", "")
                .toLowerCase()
                .indexOf(this.advancedSearch.toLowerCase()) > -1
            }else{
              return this.$h
              .get(field, "group.label", "")
              .toLowerCase()
              .indexOf(this.advancedSearch.toLowerCase()) > -1
            }
          }
        );
      }else{
        return this.bindFields.filter(
          (field) =>
            this.$h
              .get(field, "group.label", "")
              .toLowerCase()
              .indexOf(this.advancedSearch.toLowerCase()) > -1
        );
      }
    },
    filtersVisible() {
      return this.filtersVisibleLength !== 0;
    },
    filtersActive() {
      return this.filtersActiveLength !== 0;
    },
    filtersActiveLength() {
      return this.bindFields.filter((field) => field.state.active).length;
    },
    filtersVisibleLength() {
      return this.bindFields.filter((field) => field.state.visible).length;
    },
    defaults() {
      return this.$h.get(Defaults, this.id, []);
    },
    defaultIntroConfigs() {
      return [
        {
          element: `#${this.id}-filters-widget-actions-help`,
          tooltipClass: "regular-tooltip-bg",
        },
        {
          element: `#${this.id}-filters-reset`,
          tooltipClass: "regular-tooltip-bg",
        },
        {
          element: `#${this.id}-filters-submit`,
          tooltipClass: "regular-tooltip-bg",
        },
        {
          element:
            "div.data-view-filters-body > div.aside > div.data-view-filters-filter-container > div.data-view-filters-search",
          tooltipClass: "regular-tooltip-bg",
        },
        {
          element:
            "div.data-view-filters-body > div.aside > div.data-view-filters-filter-container > ul.data-view-filters-filter-list",
          tooltipClass: "regular-tooltip-bg",
        },
        {
          element:
            "div.data-view-filters-body > div.body > div.data-view-filters-action-bar > div.hide-empty-filters",
          tooltipClass: "regular-tooltip-bg",
        },
        {
          element:
            "div.data-view-filters-body > div.body > div.data-view-filters-action-bar > a#studies-defaults",
          tooltipClass: "regular-tooltip-bg",
        },
      ];
    },
    introSteps() {
      return this.handleIntroConfigs(
        this.introConfigs,
        this.defaultIntroConfigs
      );
    },
    useMultForm() {
      return (
        this.$route.name === "participants" &&
        this.$h.hasAccess("participants") &&
        !this.$store.getters["settings/containerEnabled"]
      );
    },
  },
  created() {
    let activeFilter = JSON.parse(
      localStorage.getItem(`${this.id}-active-filter`)
    );
    if (activeFilter) {
      this.applyFilter(activeFilter);
    } else {
      this.values = this.$h.cloneDeep(this.initialValues);
    }
  },
  mounted() {
    this.selectedTab = localStorage.getItem('selectedTabID')
    this.$nextTick(this.showOnlyActiveFields);
    this.submit(false);
    this.getFilters();
    this.originalFilters = this.bindFields;
    // set filter visible scroll handler
    this.$nextTick(() => {
      if (this.$refs.scroll) {
        this.$refs.scroll.addEventListener("scroll", this.setHiddenFilters);
      }
    });
  },
  methods: {
    correctECActiveValues() {
      this.searchedFields.filter(sfEl => {
        if ((sfEl.id === 'study-participant-char-age' && (!showAge(sfEl.value, 'min') && !showAge(sfEl.value, 'max'))) || 
            ((sfEl.id === 'study-participant-char-priorTherapy' && !hasSECFilterValues(sfEl.value, sfEl.id))) ||
            ((sfEl.id === 'study-participant-char-sex' && ((!truthy(sfEl.value['sex_criteria']) || sfEl.value['sex_criteria'].length == 0))))) {
            sfEl.state.active = false;
            sfEl.state.visible = false;
        }
      });
    },
    studyTab() {
      this.tab = 'study'
      this.correctECActiveValues()
    },
    submit(flag = false) {
      this.openFiltersOnSubmit = flag === true;

      if (this.filtersActive) {
        this.$emit("update:selectedTab", this.selectedTab);
      }

      if (flag) {
        //-- Ensure we reset the activeFilter state, if the filter is not active
        this.bindFields.forEach((filter, index, thisBindFields) => {
          /* 
            Added the check truthy(filter.value) to handle following the scenarios: 
            1. Apply a study filter from A-Z
            2. Unselect the study filter from A-Z
            3. Apply filters and the unselected filter should not display

            Why we need truthy(filter.value):
            1. When unselecting a filter, the state.active will only be updated in the nexttick (after refreshing the page).
            Therefore, state.active is still true, but the filter.value is cleared. If this is the case, we need to reset the state.
          */
          if (filter.state.active && truthy(filter.value)) {
            if(filter.id === "lead-disease"){
              // Turn off setLeadDiseaseSearchHomeDashboard flag when user has applied lead disease filter from filter options
              this.$store.commit('search/setLeadDiseaseSearchHomeDashboard', false);
            }
            filter.state.applyFilter = true;
            filter.applyFilter = true;
          } else {
            // If a filter is not selected (not active), or if the filter.value is cleared, do not show it on the page.
            filter.applyFilter = false;
            filter.state.applyFilter = false;
            filter.state.active = false;  // added this line to fix the issue when filter is unselected by clicking on the (+) sign but still active on the page.
          }
          thisBindFields[index] = filter;
        });
      }

      if (this.$refs["filters-form"]) {
        this.$refs["filters-form"].dSubmit();
        this.$nextTick(this.updateActiveFilters);
      }
    },
    clearSearch(){
      this.advancedSearch = "";
    },
    clearGroupedFilters() {
      this.advancedSearch = ''
      this.activeCTFinderType = 'filter-groups'
      this.selectedTab = 'filter-groups'
    },
    showDataSettings(){
      // only show data settings popover when "Disease/Condition" or "Lead Disease" is chosen.
      return this.searchedFields.some(field => (field.id == "disease" && field.state?.visible === true) || (field.id == "lead-disease" && field.state?.visible === true));
    },
    resetFilters(flag = true) {
      if (flag === false && this.id === "studies") {
        // this is when clicking on the Reset Filters button on Studies page
        this.resetStudyFilters();
      }
      this.values = {};
      this.$refs["filters-form"].resetFields();
      // Also reset the Groups tab when resetting the filters.
      if(this.$refs["grouped-study-filters"]){
        this.$refs["grouped-study-filters"].resetFilterState();
      }
      if(this.$refs["az-study-filters"]){
        this.$refs["az-study-filters"].resetFilterState();
      }
      this.onlyActiveFilters = false;
      this.tab = "";
      this.showOnlyActiveFields();
      this.clearActiveFilter();
      this.filterCounts.study = 0
      this.filterCounts.participant = 0

      if (flag) {
        this.$nextTick(this.submit);
      }
    },
    resetStudyFilters() {
      this.selectedTab = 'filter-groups';
      this.$emit("update:selectedTab", this.$h.cloneDeep(this.selectedTab));
      
      // When clearing filters on studies page, remove any study filters but keep participants filters
      let model = this.$store.getters["form/getForm"]("participants-filters");
      if (model !== undefined) {
        let newModel = {};
        // save participants filters and remove study filters
        Object.keys(model).forEach((key) => {
          // if the key doesnt exist in studyKeys, then it is a participant filter and should be saved
          if (!StudyKeys.studyKeys.includes(key) || key === "_keyword") {
            newModel[key] = model[key];
          }
        });

        // update participants-filters form in the store
        this.$store.dispatch("form/saveForm", {
          id: "participants-filters",
          model: newModel,
        });
      }
    },
    isAnyActiveField() {
      const isNotMLDAndNone =
        this.bindFields.filter(
          (field) =>
            field.id === "lead-disease" &&
            field.value &&
            field.value.length > 1 &&
            field.value.filter(
              (item) =>
                item.maintype === "Multiple Lead Diseases" ||
                item.maintype === "None"
            ) > 0
        ).length === 0;
      const numberOfFiltersToCheck = isNotMLDAndNone ? 1 : 0;
      return (
        this.bindFields.filter((field) => field.state.active).length >=
        numberOfFiltersToCheck
      );
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    removeDuplicateParamValues(params) {
      for (const key in params) {
        if (Array.isArray(params[key])) {
          params[key] = params[key].filter(this.onlyUnique);
        }
      }
      return params;
    },
    filtersSubmit(params) {
      // For Studies page - onlyFilter submit logic to set/unset Disease/Lead Disease flags 
      if (this.searchedFields.some(field => (field.id == "disease" && field.state?.active === true))) {
        this.$store.commit('search/setDiseaseConditionSearch', true)
      } 
      else if (this.searchedFields.some(field => (field.id == "lead-disease" && field.state?.active === true))){
        this.$store.commit('search/setLeadDiseaseSearch', true)
      } else if (this.searchedFields.some(field => (field.id =="disease_evs_id" && field.state?.active === true))) {
        this.$store.commit('search/setParticipantDiseaseConditionSearch', true)
      } else {
        this.$store.commit('search/setDiseaseConditionSearch', false)
        this.$store.commit('search/setLeadDiseaseSearch', false)        
      }
      // For Participants page - onlyFilter submit logic to set/unset Disease/Lead Disease flags 
      if (this.searchedFields.some(field => (field.id =="disease_evs_id" && field.state?.active === false))) {
        this.$store.commit('search/setParticipantDiseaseConditionSearch', false)
      }
      params = this.removeDuplicateParamValues(params);
      this.values = this.$h.cloneDeep(this.bindValues);

      if (!this.filtersActive) {
        this.selectedTab = "filter-groups"
        localStorage.removeItem('selectedTabID');
      }

      this.$emit("update:bindFields", this.$h.cloneDeep(this.bindFields));
      this.$emit("update:bindValues", this.$h.cloneDeep(this.bindValues));
      this.$emit(
        "update:bindParams",
        this.formatParams(this.$h.cloneDeep(params))
      );

      if (!this.openFiltersOnSubmit) {
        this.openFiltersOnSubmit = false;
        this.closeFilters();
      }
      this.$emit("filtersSubmit", this.$h.cloneDeep(params));
    },
    openFilters() {
      if (this.open) {
        this.showOnlyActiveFields();
        this.$refs.filters.show();
      }
    },
    closeFilters() {
      if (this.open) {
        this.$refs.filters.hide();
        this.$emit("close");
      }
    },
    cancelChanges() {
      this.closeFilters();
      this.$root.$emit("bv::hide::popover", `${this.id}-filters-settings`);
      //updateFilters() does a bunch of other nonsense; i think we can just close the popover manually
      //as above, and be good to go
      // this.updateFilters();
    },
    updateFilters() {
      this.$refs["filters-form"].setUpFields();
      this.submit(true);
    },
    filtersShown() {
      this.setLastFieldIndex();
      this.$nextTick(() => {
        if (this.$refs["filter-header"]) {
          this.$refs["filter-header"].scrollIntoView(false);
        }
      });
    },
    toggleField(field) {
      if(field.id === "study-participant-char-priorTherapy" && this.activeCTFinderType == 'filter-az'){
        if(!field.state.active){
          // Scenario when field is not yet selected, and user is selecting the field. We want to make the field active and visible.
          this.toggleFieldVisiblePriorTherapy(field);
        }else{
          let priorTherapyFiltersGroup = ["study-participant-char-priorTherapy", "study-participant-char-sex", "study-participant-char-age"];
          priorTherapyFiltersGroup.forEach( (ptFilterName)=> {
            this.removePriorTherapyFilter(ptFilterName);
          });
          this.toggleFieldVisiblePriorTherapy(field);
        }
      }else{
        this.toggleFieldVisible(field);
      }
    },
    toggleFieldVisible(field) {
      this.$refs["filters-form"].resetField(field);
      this.$refs["filters-form"].updateFieldState(
        field.id,
        "visible",
        !field.state.visible
      );

      // Update the states of the field in the left panel of the filter window
      this.updateLeftPanelFiltersStates(field);

      this.$nextTick(this.setLastFieldIndex);
      setTimeout(() => {
        let newElement = document.getElementById(
          `${this.id}-filters-${field.id}`
        );
        if(newElement){
          if (field.state.visible) {
            newElement.classList.add("flash");
            this.$refs.body.scrollTop = newElement.offsetTop - 88;
          } else {
            newElement.classList.remove("flash");
          }
        }
      }, 100);
    },
    toggleFieldVisiblePriorTherapy(field){
      let PTfields = ["study-participant-char-priorTherapy", "study-participant-char-sex", "study-participant-char-age"];
      // For each field in the Prior Therapy filter, run the logic in toggleFieldVisible()
      PTfields.forEach( (PTfieldName) => {
        for(let i = 0; i < this.searchedFields.length; i++){
          if(this.searchedFields[i].id === PTfieldName){
            this.toggleFieldVisible(this.searchedFields[i]);
          }
        }
      });
    },
    removePriorTherapyFilter(filterName){
      // Clear prior therapy filters (age, sex, prior therapy) when user deselects prior therapy in A-Z tab.
      let ptFilter = this.$refs["filters-form"].findField(filterName);
      let ptFilterValue = Object.prototype.hasOwnProperty.call(ptFilter, "default") ? ptFilter.default : undefined;

      const storeModel = this.persist
      ? this.$store.getters["form/getForm"](`${this.id}-filters`)
      : {};

      storeModel[filterName] = ptFilterValue;
      this.$store.dispatch("form/saveForm", {
        id: `${this.id}-filters`,
        model: storeModel,
      });

      this.$emit("update:bindActiveFilter", {});

      this.values[filterName] = {};

      // save filter to localstorage
      localStorage.removeItem(`${this.id}-active-filter`);
    },
    updateLeftPanelFiltersStates(field){
      // Only need to update "active" state for now, add more states as needed.
      for(let i = 0; i < this.bindFields.length; i++){
        if (this.bindFields[i].id === field.id) {
          if (field.id === '_keyword_form') {
            let val = this.bindFields[i].value
          }
          if (field.id === 'study-participant-char-age') {
            let val = this.bindFields[i].value
          }
          if(!truthy(this.bindFields[i].value)){
            // Make sure empty value filters are not displayed/active
            this.bindFields[i].state.active = false;
          }else{
            this.bindFields[i].state.active = !this.bindFields[i].state.active;
          }
        }
      }
    },
    showOnlyActiveFields() {
      this.bindFields.forEach((field) => {
        this.$refs["filters-form"].updateFieldState(
          field.id,
          "visible",
          field.state.active
        );
      });
    },
    setFormValue(id, value) {
      this.$refs["filters-form"].updateValue(id, value);
      this.$nextTick(this.submit);
    },
    updateActiveFilters() {
      if (!this.filtersActive) {
        this.onlyActiveFilters = false;
        this.lastFieldIndex = -1;
      }
    },
    setLastFieldIndex() {
      let lastFieldIndex = -1;
      this.bindFields.forEach((field, index) => {
        if (field.state.visible) {
          lastFieldIndex = index;
        }
      });
      this.lastFieldIndex = lastFieldIndex;
    },
    openHelpGuide() {
      this.startIntro(this.introSteps);
    },
    formatParams(params) {
      //-- Ensure to update the params for lead disease, only if the user has applied filters
      this.bindFields.forEach((filter, index, thisBindFields) => {
        if (filter.id === "lead-disease" && filter.state.applyFilter) {
          updateLDParamsForFilters(params);
        }
      });

      if (this.$route.name === "participants") {
        return formatParams(params);
      } else {
        return formatStudiesParams(
          params,
          this.openFiltersOnSubmit && this.isAnyActiveField()
        );
      }
    },
    setCounts(fields) {
      let study = 0;
      let participant = 0;
      fields.forEach(({field, tab, active }) => {
        if (active) {
          if (tab === "study") {
            /* STRP-2658: Special check for the Eligibility Criteria fields before incrementing the count, 
               as their state comes in as 'active' even if they are not selected filters */
            if (field.id === "study-participant-char-age") {
              if (this.validateAgeValueIsNotNull(field)) {
                study++;
              }
            } else if (field.id === "study-participant-char-priorTherapy") {
              if (this.validateSECValueIsNotNull(field)) {
                study++;
              }
            } else if (field.id === "study-participant-char-sex") {
                if ((truthy(field.value['sex_criteria']) && field.value['sex_criteria'].length > 0)) {
                  study++;
                }
            } 
            else {
                study++;
            }
          } else {
            if (tab === "participant") {
              this.activeFilterType == 'filter-az'
              participant++;
            }
          }
        }
      });
      this.filterCounts = {
        study,
        participant,
      };
      //this.setInterventionActive(fields);
    },
    setInterventionActive(fields) {
      const interventionIndex = this.searchedFields.findIndex(
        (ele) => ele.id == "agent-intervention"
      );
      if (
        fields[interventionIndex].active ||
        fields[interventionIndex + 1].active ||
        fields[interventionIndex + 2].active
      ) {
        this.searchedFields[interventionIndex - 1].state.active = true;
        this.searchedFields[interventionIndex].state.active = true;
        this.searchedFields[interventionIndex + 1].state.active = true;
        this.searchedFields[interventionIndex + 2].state.active = true;
      }
    },

    // new
    applyFilter(filter) {
      this.$root.$emit("bv::hide::popover", `${this.id}-filters-settings`);
      this.submit();
      this.closeFilters();
    },
    editFilter(filter) {
      this.setApplyFilterFlag(true);
      this.setActiveFilter(filter);
      this.$root.$emit("bv::hide::popover", `${this.id}-filters-settings`);
    },
    cancelFilter() {
      this.setActiveFilter();
    },
    setApplyFilterFlag(flag) {
      this.$refs["filters-form"].setApplyFilterFlag(flag);
    },
    setActiveFilter(filter = {}) {
      this.$emit("update:bindActiveFilter", filter);
      this.activeFilter = filter;
      this.values = this.$h.cloneDeep(filter.values);
      this.$store.dispatch("form/clearForm", `${this.id}-filters`);
      this.$nextTick(this.updateFilters);

      // save filter to localstorage
      localStorage.setItem(`${this.id}-active-filter`, JSON.stringify(filter));
    },
    clearActiveFilter() {
      this.$emit("update:bindActiveFilter", {});
      this.activeFilter = undefined;
      this.values = {};
      this.$store.dispatch("form/clearForm", `${this.id}-filters`);
      this.$nextTick(this.updateFilters);

      // save filter to localstorage
      localStorage.removeItem(`${this.id}-active-filter`);
    },
    validateAgeValueIsNotNull(field){
      // Check if any of the age fields have non-null values
      if (field.id === "study-participant-char-age") {
        return (showAge(field.value, 'min')) || (showAge(field.value, 'max'))
      }else{
        return true;
      }
    },
    validateSECValueIsNotNull(field){
      // Check if any of the age fields have non-null values
      if (field.id === "study-participant-char-priorTherapy") {
        return hasSECFilterValues(field.value, field.id)
      }else{
        return true;
      }
    },
    async getFilters() {
      try {
        this.filterState.loading = true;
        this.filterState.error = undefined;
        let response = await apiGet(this.filterQuery);
        this.filterState.response = response;
        this.userFilters = response.map((f) => f.filter_name);
      } catch (error) {
        this.filterState.loading = false;
        this.filterState.error = error;
      } finally {
        this.filterState.loading = false;
      }
    },

    async filterSaveSubmit() {
      if (this.userFilters.includes(this.filterSaveLabel)) {
        this.filterLabelError = true;
        return;
      }

      try {
        this.filterSaveState.loading = true;
        this.filterSaveState.error = undefined;
        await apiGet(this.filterSaveQuery);
        this.banner.filterName = this.filterSaveLabel;
        this.banner.show = true;
        this.setActiveFilter({
          label: this.filterSaveLabel,
          values: this.filterSaveQuery.data.filter_object,
        });
      } catch (error) {
        this.filterSaveState.loading = false;
        this.filterSaveState.error = error;
      } finally {
        this.getFilters();
        this.filterSaveLabel = "";
        this.filterSaveState.loading = false;
        this.$root.$emit("bv::hide::popover", `${this.id}-filters-bookmark`);
        this.$root.$emit(
          "bv::hide::popover",
          `${this.id}-filters-bookmark-save-as`
        );
      }
    },
    async filterUpdate() {
      if (this.userFilters.includes(this.filterSaveLabel)) {
        this.filterLabelError = true;
        return;
      }

      try {
        this.filterSaveState.loading = true;
        this.filterSaveState.error = undefined;
        let filterValues = filterParams(this.$h.cloneDeep(this.bindValues));
        await apiGet({
          baseURL: "users",
          endpoint: `/user/filters/${this.activeFilter.id}`,
          method: "PUT",
          data: {
            // filter_type: "",
            filter_name: this.filterSaveLabel,
            filter_object: setResourceFilter(filterValues, this.resource),
          },
        });
        this.setActiveFilter({
          ...this.activeFilter,
          values: filterValues,
        });
      } catch (error) {
        this.filterSaveState.loading = false;
        this.filterSaveState.error = error;
      } finally {
        this.getFilters();
        this.filterSaveState.loading = false;
      }
    },
    async deleteFilter(id) {
      if (this.activeFilter && id === this.activeFilter.id) {
        this.clearActiveFilter();
      }
      try {
        this.filterSaveState.loading = true;
        await apiGet({
          baseURL: "users",
          endpoint: `/user/filters/${id}`,
          method: "DELETE",
        });
      } catch (error) {
        this.filterSaveState.loading = false;
      } finally {
        this.getFilters();
        this.filterSaveState.loading = false;
      }
    },
    closeFilterPopup() {
      this.$root.$emit("bv::hide::popover", `${this.id}-filters-settings`);
    },

    startIntro,
    handleIntroConfigs,
  },
  watch: {
    open: "openFilters",
    filtersActive(flag) {
      if (!flag) {
        this.onlyActiveFilters = false;
      }
    },
  },
  destroyed() {
    if (this.$refs.scroll) {
      this.$refs.scroll.removeEventListener("scroll", this.setHiddenFilters);
    }
  },
};

function filterParams(params) {
  for (let key in params) {
    if (
      Object.prototype.hasOwnProperty.call(params, key) &&
      !truthy(params[key])
    ) {
      delete params[key]; // delete empty values
    }
  }
  return params;
}
</script>

<style lang="scss">
@import "src/styles/component";

.filter-notification {
  position: absolute;
  width: 175px;
  padding: 0.35rem;
  text-align: center;
  left: 2rem;
  background-color: $studies !important;
  border: solid thin $studies;
  color: lighten($studies, 50);
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  z-index: 99;
  font-size: 0.85rem;

  .icon {
    position: absolute;
    right: 0.75rem;
    top: 0.65rem;
  }

  &.above {
    top: 8rem;
    display: none;
  }

  &.below {
    bottom: 1rem;
  }
}

.filter-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  background-color: fade-out($green, 0.95);
  border-bottom: solid 1px fade-out($green, 0.5);
  font-size: 0.9;

  .close {
    position: absolute;
    right: 1rem;
    transform: scale(0.8);
    background-color: #fff !important;

    &:active {
      background-color: transparent !important;
    }
  }

  &.editing {
    justify-content: space-between;
    padding: 0 1rem;
    height: 55px;
    background-color: fade-out($yellow, 0.5);
    border-bottom: solid 1px fade-out($yellow, 0.5);

    .label {
      font-size: 1.2rem;
    }

    .btn {
      margin-left: 0.5rem;
    }
  }
}

.bookmark-popover {
  width: 275px;
  padding: 0.5rem;

  .input {
    margin-bottom: 0.25rem;

    &.invalid {
      border-color: red !important;
    }
  }

  >button {
    margin-top: 1rem;
  }

  &.loading {
    pointer-events: none;
    opacity: 0.5;
  }

  .error-message {
    color: red;
    font-size: 0.85rem;
  }
}

.body.useMultForm {
  .tab-participant {
    background-color: fade-out($participants, 0.95);
    border-left: solid 4px $participants;
  }

  .tab-study {
    background-color: fade-out($studies, 0.95);
    border-left: solid 4px $studies;
  }

  .tab-participant-group {
    background-color: fade-out($participants, 0.95);
    border-left: solid 4px $participants;
    padding-left: 2rem;
    padding-bottom: 0.5rem;
  }

  .tab-study-group {
    background-color: fade-out($studies, 0.95);
    border-left: solid 4px $studies;
    padding-left: 2rem;
    padding-top: 0.01rem;
    padding-bottom: 0.01rem;
  }
}

.body.editing {
  background-color: fade-out($yellow, 0.9);
}

.data-view-filters-list-back {
  padding: 1rem;
  display: block;

  &:hover {
    background-color: $grey-1;
    cursor: pointer;
  }

  &:active {
    background-color: $grey-2;
  }

  .icon {
    margin-right: 0.5rem;
    font-size: 0.9rem;
  }
}

.data-view-filters-list-header {
  padding: 0.75rem 1rem;
  font-weight: $bold;
  font-size: 1.15rem;
  color: white;
  display: block;

  &.study {
    background-color: $studies;
  }

  &.participant {
    background-color: $participants;
  }
}

.data-view-filters-tab {
  padding: 1rem;
  display: flex;
  flex-direction: column;

  button {
    text-align: left;
    background: white;
    border: none;
    padding: 0.65rem 0.75rem;
    cursor: pointer;
    font-size: 1.15rem;
    outline: none !important;

    &:hover {
      color: $participants;
      background-color: $n1;
    }
  }

  h4 {
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
    margin-left: 0.75rem;
    display: none;
  }
}

.data-view-filters-filter-list:not(.default) {
  height: calc(80vh);
  overflow-y: hidden; // eliminates double scrollbar

  .advanced-filter-list-item {
    display: none;
  }

  &.show-study .tab-study {
    display: flex;
  }

  &.show-study {
    // participants page study filter heights
    .data-view-filters-filter-list, #accordion-header {
      height: calc(80vh - 179px); // for both a-z and groups filter view
    }
  }

  &.show-participant .tab-participant {
    display: flex;
  }
}

// modal
.data-view-filters {
  z-index: 9999999;

  .modal {
    overflow-x: auto;
    overflow-y: hidden !important;

    .modal-dialog {
      max-width: 900px;
      min-width: 900px;
    }

    .modal-body {
      padding: 0;
      background-color: #fff;
      border-radius: $border-radius;
      // overflow: hidden;
    }
  }

  .btn-outline-button {
    font-size: 0.8rem !important;
    padding-top: 0.15rem !important;
    border-radius: 0.25rem !important;

    label:before,
    label:after {
      transform: translateY(-3px);
    }
  }
}

// header
.data-view-filters-container {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  height: 74px;

  .header-logo-name {
    padding-top: 0.1rem;
  }

  .entity-logo {
    font-size: 0.7rem !important;
  }

  .name h1 {
    font-size: 1.15rem !important;
  }

  .btn {
    margin-left: 0.5rem;

    &.submit {
      width: 230px;
      // border-radius: 25px;
    }

    &:not(.close) svg {
      margin-right: 0.5rem;
    }
  }
}

#studies-filters-widget-actions-help,
#investigators-filters-widget-actions-help,
#organizations-filters-widget-actions-help,
#participants-filters-widget-actions-help {
  svg {
    margin-right: 0;
  }
}

// body
.data-view-filters-body {
  display: flex;
  min-height: 75vh;
  overflow: hidden;

  .aside {
    flex: 0 0 240px;
    height: 80vh;
  }

  .body {
    flex: 0 1 100%;
    height: 80vh;
    overflow-y: auto;
  }
}

// sub items
.data-view-filters-filter-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-right: solid thin $n3;
  z-index: 9;
}

.filter-toggle-btn {
  padding: 1rem;
}

.data-view-filters-search {
  padding: 1rem;

  svg {
    position: absolute;
    margin-top: 0.7rem;
    right: 2rem;
    font-size: 0.9rem;
    color: $text;
  }

  input {
    padding-left: 1rem;
    padding-right: 2rem;
    border-radius: 25px;
    height: 38px;
    background-color: $n1;
    font-size: 0.9rem;
  }
}

.data-view-filters-filter-list {
  padding: 0;
  margin: 0;
  overflow-y: auto;
  height: calc(80vh - 70px) ; // entity page filter list height

  &.studies-page {
    height: calc(80vh - 141px) ;  // a-z view filter list height
  }

  &.participants-page {
    height: calc(80vh - 107px) ; // participants filter list height
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $n3;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($n3, 5);
    cursor: hand;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $n4;
    cursor: hand;
  }

  &::-webkit-scrollbar-track {
    background-color: $n1;
  }

  &::-webkit-scrollbar {
    width: 5px;
    margin-left: -3px;
    height: 5px;
  }
}

.advanced-filter-list-item {
  display: block;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-top: solid thin transparent;
  border-bottom: solid thin transparent;
  cursor: pointer;

  font-size: 0.9rem;
  font-weight: $thin;
  color: $text;

  .plus-minus {
    font-size: 0.8rem;
  }

  &.hide {
    display: none;
  }

  &:not(.visible) {
    .plus-minus {
      color: fade-out($text, 0.75);
    }

    // &:hover .plus-minus{
    //   color: $green;
    // }
  }

  &.visible {
    color: $link;
    font-weight: $regular;
    background-color: mix($link, white, 10);
  }

  &:hover {
    background-color: $n1;
  }

  &:active {
    color: white !important;
    background-color: $link;

    .plus-minus {
      color: white;
    }
  }

  &.visible:active {
    color: white;
    background-color: $danger;

    .plus-minus {
      color: white;
    }
  }

  &.visible:hover {
    color: $danger;

    svg {
      color: $danger;
    }

    &.active:after {
      background: $danger;
      box-shadow: 0 0 0 3px fade-out($danger, 0.5);
    }
  }

  &.clear {
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;

    &:hover {
      background-color: $n1 !important;
    }
  }

  &.empty {
    text-align: center;
    opacity: 0.75;
    pointer-events: none;
  }
}

.data-view-filters-action-bar {
  width: 100%;
  background-color: white;
  border-bottom: solid thin $n3;
  padding: 0.75rem 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  >* {
    flex: 0 0 auto;
  }

  .hide-empty-filters {
    flex: 0 0 auto;

    color: $text;
    font-weight: $regular;
    margin-top: 0.1rem;
    margin-right: 0.5rem;
    font-size: 0.9rem;
  }

  .btn {
    font-size: 0.9rem;
  }
}

.data-view-filters-form {
  width: 100%;
  padding: 0 !important;

  >.form-field {
    padding: 1.5rem 2rem 0.75rem !important;
  }

  @keyframes field {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.data-view-fields-empty {
  position: absolute;
  top: 20rem;
  left: 0;
  right: 0;
  text-align: center;
  padding-left: 280px;
  z-index: 0;
  color: $text-light;
  font-size: 1.3rem;
  opacity: 0.5;

  svg {
    margin-right: 1rem;
    margin-top: 0.25rem;
    font-size: 1.5rem;
  }
}

.data-view-filters-defaults {
  padding: 0 !important;
  margin: 0;

  .default-item {
    display: block;
    padding: 0.5rem 0.75rem;
    margin: 0;
    min-width: 150px;
    border-radius: $border-radius;

    font-size: 1rem;
    cursor: pointer;

    .filter-num {
      margin-left: 0.5rem;
    }

    &:hover {
      background-color: $light;
      // color: $link;
    }
  }
}

.flash {
  animation: flash 0.75s ease !important;
}

@keyframes flash {
  0% {
    background-color: fade-out(black, 1);
    // border-color: fade-out($link, 0);
  }

  25% {
    background-color: fade-out(black, 0.95);
    // border-color: fade-out($link, 0.25);
  }

  50% {
    background-color: fade-out(black, 1);
    // border-color: fade-out($link, 0);
  }

  75% {
    background-color: fade-out(black, 0.95);
    // border-color: fade-out($link, 0.25);
  }

  100% {
    background-color: fade-out(black, 1);
    // border-color: fade-out($link, 0);
  }
}

label[for="studies-filters-interventions-input"].d-block,
label[for="participants-filters-interventions-input"].d-block,
label[for="participants-filters-funding-input"].d-block,
label[for="studies-filters-funding-input"].d-block {
  display: none !important;
}

.filter-toggle-btn {
  padding: 1rem;
  // display: none;  // comment out this line when testing grouping filters functionality
}
</style>
